export const patientInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-2 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Patient First Name',
        name: 'patientfirstname',
        key: 'patientfirstname',
      },
      {
        type: 'text',
        label: 'Patient Last Name',
        name: 'patientlastname',
        key: 'patientlastname',
      },
      {
        type: 'text',
        label: 'Patient Date of Birth',
        name: 'patientdateofbirth',
        key: 'patientdateofbirth',
      },
    ],
  },
]
export const callerInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-2 tw-gap-6',
    children: [
      {
        type: 'date',
        label: 'Date',
        name: 'date',
        key: 'date',
      },
      {
        type: 'time',
        label: 'Time of Call',
        name: 'time',
        key: 'time',
      },
      {
        type: 'text',
        label: 'Caller of Verbal Prescription',
        name: 'callerofRx',
        key: 'callerofRx',
        class: 'tw-col-span-2',
      },

    ],
  },
]
export const medone = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-2 tw-gap-6',
    children: [

      {
        type: 'text',
        label: 'Medication Name 1',
        name: 'medicationName1',
        key: 'medicationName1',
      },
      {
        type: 'text',
        label: 'Medication Instructions',
        name: 'medicationInstructions1',
        key: 'medicationInstructions1',
      },
      {
        type: 'text',
        label: 'Medication Quantity',
        name: 'medicationQuantity1',
        key: 'medicationQuantity1',
      },
      {
        type: 'text',
        label: 'Medication Repeats',
        name: 'medicationRepeats1',
        key: 'medicationRepeats1',
      },
    ],
  },
]
export const medtwo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-2 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Medication Name 2',
        name: 'medicationName2',
        key: 'medicationName2',
      },
      {
        type: 'text',
        label: 'Medication Instructions',
        name: 'medicationInstructions2',
        key: 'medicationInstructions2',
      },
      {
        type: 'text',
        label: 'Medication Quantity',
        name: 'medicationQuantity2',
        key: 'medicationQuantity2',
      },
      {
        type: 'text',
        label: 'Medication Repeats',
        name: 'medicationRepeats2',
        key: 'medicationRepeats2',
      },
    ],
  },
]
export const medthree = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-2 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Medication Name 3',
        name: 'medicationName3',
        key: 'medicationName3',
      },
      {
        type: 'text',
        label: 'Medication Instructions',
        name: 'medicationInstructions3',
        key: 'medicationInstructions3',
      },
      {
        type: 'text',
        label: 'Medication Quantity',
        name: 'medicationQuantity3',
        key: 'medicationQuantity3',
      },
      {
        type: 'text',
        label: 'Medication Repeats',
        name: 'medicationRepeats3',
        key: 'medicationRepeats3',
      },
    ],
  },
]
export const doctorinfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-2 tw-gap-6',
    children: [

      {
        type: 'text',
        label: 'Doctor First Name',
        name: 'drfirstname',
        key: 'drfirstname',
      },
      {
        type: 'text',
        label: 'Doctor Last Name',
        name: 'drlastname',
        key: 'drlastname',
      },
      {
        type: 'text',
        label: 'CPSO#',
        name: 'doctorCpso',
        key: 'doctorCpso',
      },
      {
        type: 'text',
        label: 'Doctor Tel Number',
        name: 'doctorTelephoneNumber',
        key: 'doctorTelephoneNumber',
      },
    ],
  },
]
export const pharmacistinfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-3 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacist First Name',
        name: 'rphfirstname',
        key: 'rphfirstname',
      },
      {
        type: 'text',
        label: 'Pharmacist Last Name',
        name: 'rphlastname',
        key: 'rphlastname',
      },
      {
        type: 'text',
        label: 'OCP#',
        name: 'ocp',
        key: 'ocp',
      },

      // {
      //   type: 'text',
      //   label: 'Rx Number',
      //   name: 'rxNumber',
      //   key: 'rxNumber',
      // },
    ],
  },
]
