<template>
  <div>
    <!-- <v-card
      id="patientformpanel"
      color="info"
    >
      <v-card-title class="justify-left">
        Verbal Prescription - {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}
      </v-card-title>
      <v-row

        :key="componentTopCardKey"
      >
        <v-col
          v-if="patientProfile.PatientID"
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</h2>
            <p>Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }} </p>
          </v-card-text>
        </v-col> -->
    <!-- <v-col
          v-if="selectedMedication.length > 0"
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text id="headerTable">
            <v-data-table
              :headers="headersCard"
              :items="selectedMedication"
              hide-default-footer
              dense
              item-key="RxNumber"
            >
              <template v-slot:item="props">
                <tr>
                  <td align="left">
                    {{ props.item['GENERIC NAME'] || props.item['GenericName'] }}
                  </td>
                  <td align="left">
                    {{ props.item['RxStrength'] || props.item['RxStrength'] }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-col> -->
    <!-- <v-col
          v-if="selectedMedication[0]"
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>Dr. {{ selectedMedication[0].FirstName }} {{ selectedMedication[0].LastName }}</h2>
            <p v-if="selectedMedication[0].DoctorFax">
              Fax: {{ selectedMedication[0].DoctorFax }} || Tel: {{ selectedMedication[0].DoctorPhone }}
            </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card
      color="pglightblue"
    >
      <v-card-title class="justify-left tw-text-white">
        <p class="tw-text-white tw-font-bold">
          Verbal Prescription -
        </p>
        <p
          v-if="patientProfile.PatientID"
          class="tw-justify-center tw-content-center tw-text-white tw-text-base tw-font-semibold"
        >
          {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}
        </p>
      </v-card-title>
    </v-card>

    <v-stepper
      v-model="activeStep"
      non-linear
      class="custom-header"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Medication Selection</span>
              <span class="text--secondary text-xs">Please select up to 3 Prescriptions from Patient File</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Data Review</span>
              <span class="text--secondary text-xs">Lets Gather the info</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Form Finalization</span>
              <span class="text--secondary text-xs">Lets Document the Request</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            id="medListId"
            :key="componentKeyMedList"
          >
            <single-medication-selection
              :selected="selectedMedication"
              @medicationupdatelist="selected"
            >
            </single-medication-selection>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4">
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
              <div class="tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="callerInfo"
                    :schema="callerInfo"
                  />
                </div>
              </div>
              <v-expansion-panels v-model="dataPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="text-base">Patient Information: {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                      <FormulateForm
                        :key="refreshFormulateForm"
                        v-model="prePopulate"
                        name="patientInfo"
                        :schema="patientInfo"
                        :keep-model-data="true"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="this.selectedMedication[0]">
                  <v-expansion-panel-header>
                    <span
                      class="text-base"
                    >Medication 1 Information: {{ this.selectedMedication[0].BrandName }} {{ this.selectedMedication[0].RxStrength }}</span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                      <FormulateForm
                        :key="refreshFormulateForm"
                        v-model="prePopulate"
                        name="medone"
                        :schema="medone"
                        :keep-model-data="true"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="this.selectedMedication[1]">
                  <v-expansion-panel-header>
                    <span
                      class="text-base"
                    >Medication 2 Information: {{ this.selectedMedication[1].BrandName }} {{ this.selectedMedication[1].RxStrength }} </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                      <FormulateForm
                        :key="refreshFormulateForm"
                        v-model="prePopulate"
                        name="medtwo"
                        :schema="medtwo"
                        :keep-model-data="true"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="this.selectedMedication[2]">
                  <v-expansion-panel-header>
                    <span
                      class="text-base"
                    >Medication 3 Information: {{ this.selectedMedication[2].BrandName }} {{ this.selectedMedication[2].RxStrength }} </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                      <FormulateForm
                        :key="refreshFormulateForm"
                        v-model="prePopulate"
                        name="medthree"
                        :schema="medthree"
                        :keep-model-data="true"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <div class="tw-mt-5 tw-col-span-2">
              <div class="d-flex justify-center align-center">
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div class="tw-card-body">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <p class="tw-font-black">
                        Patient Details:
                      </p>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start">
                      <div>{{ this.prePopulate['LAST NAME'] }}, {{ this.prePopulate['FIRST NAME'] }}</div>
                      <div>Gender: {{ this.prePopulate.GENDER }}</div>
                      <div>DOB: {{ this.prePopulate['DATE OF BIRTH'] }}</div>
                      <div>OHIP Number: {{ this.prePopulate['HEALTH CARD NUMBER'] }}</div>
                      <div>Telephone: {{ this.prePopulate['TELEPHONE NUMBER'] }}</div>
                    </div>
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <p class="tw-font-black tw-pt-6">
                        Doctor Details:
                      </p>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start">
                      <div>Dr. {{ this.prePopulate.FirstName }} {{ this.prePopulate.LastName }}</div>
                      <div>CPSO: {{ this.prePopulate.CPSO }}</div>
                      <div>Tel: {{ this.prePopulate.DoctorPhone }}</div>
                      <div>Fax: {{ this.prePopulate.DoctorFax }}</div>
                      <!-- <div>Rx #: {{ this.prePopulate.RxNumber }}</div>
                      <div>Rx Date: {{ this.prePopulate.RxDate }}</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="tw-pt-6 d-flex justify-center align-center">
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div class="tw-card-body">
                    <div class="tw-grid  tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <p class="tw-font-black">
                        1. Medication Details:
                      </p>
                    </div>
                    <div class="tw-grid tw-grid-cols-1 tw-justify-items-start tw-items-start">
                      <div>{{ this.prePopulate.BrandName }} ({{ this.prePopulate.GenericName }}) {{ this.prePopulate.RxStrength }} {{ this.prePopulate.Form }}</div>
                      <p class="tw-font-black tw-pt-3">
                        Instructions:
                      </p>
                      <div>
                        {{ this.prePopulate.SIGFull }}
                      </div>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                      <div>Quantity: {{ this.prePopulate.QtyDispense }}</div>
                      <div>Days Supply: {{ this.prePopulate.Days }}</div>
                      <div>Total Quantity Authorized: {{ this.prePopulate.TotalAuthorized }}</div>
                      <div>Quantity Remaining: {{ this.prePopulate.QtyRemain }}</div>
                    </div>
                    <div v-if="this.selectedMedication.length>1">
                      <div class="tw-grid  tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                        <p class="tw-font-black tw-pt-6">
                          2. Medication Details:
                        </p>
                      </div>
                      <div class="tw-grid tw-grid-cols-1 tw-justify-items-start tw-items-start">
                        <div>{{ this.prePopulate.BrandName1 }} ({{ this.prePopulate.GenericName1 }}) {{ this.prePopulate.RxStrength1 }} {{ this.prePopulate.Form1 }}</div>
                        <p class="tw-font-black tw-pt-3">
                          Instructions:
                        </p>
                        <div>
                          {{ this.prePopulate.SIGFull1 }}
                        </div>
                      </div>
                      <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                        <div>Quantity: {{ this.prePopulate.QtyDispense1 }}</div>
                        <div>Days Supply: {{ this.prePopulate.Days1 }}</div>
                        <div>Total Quantity Authorized: {{ this.prePopulate.TotalAuthorized1 }}</div>
                        <div>Quantity Remaining: {{ this.prePopulate.QtyRemain1 }}</div>
                      </div>
                    </div>
                    <div v-if="this.selectedMedication.length>2">
                      <div class="tw-grid  tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                        <p class="tw-font-black tw-pt-6">
                          3. Medication Details:
                        </p>
                      </div>
                      <div class="tw-grid tw-grid-cols-1 tw-justify-items-start tw-items-start">
                        <div>{{ this.prePopulate.BrandName2 }} ({{ this.prePopulate.GenericName2 }}) {{ this.prePopulate.RxStrength2 }} {{ this.prePopulate.Form2 }}</div>
                        <p class="tw-font-black tw-pt-3">
                          Instructions:
                        </p>
                        <div>
                          {{ this.prePopulate.SIGFull2 }}
                        </div>
                      </div>
                      <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                        <div>Quantity: {{ this.prePopulate.QtyDispense2 }}</div>
                        <div>Days Supply: {{ this.prePopulate.Days2 }}</div>
                        <div>Total Quantity Authorized: {{ this.prePopulate.TotalAuthorized2 }}</div>
                        <div>Quantity Remaining: {{ this.prePopulate.QtyRemain2 }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-1">
            <v-expansion-panels v-model="dataPanel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="text-base">Patient Information: {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                    <FormulateForm
                      :key="refreshFormulateForm"
                      v-model="prePopulate"
                      name="patientInfo"
                      :schema="patientInfo"
                      :keep-model-data="true"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="this.selectedMedication[0]">
                <v-expansion-panel-header>
                  <span
                    class="text-base"
                  >Medication 1 Information: {{ this.selectedMedication[0].BrandName }} {{ this.selectedMedication[0].RxStrength }}</span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                    <FormulateForm
                      :key="refreshFormulateForm"
                      v-model="prePopulate"
                      name="medone"
                      :schema="medone"
                      :keep-model-data="true"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="this.selectedMedication[1]">
                <v-expansion-panel-header>
                  <span
                    class="text-base"
                  >Medication 2 Information: {{ this.selectedMedication[1].BrandName }} {{ this.selectedMedication[1].RxStrength }} </span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                    <FormulateForm
                      :key="refreshFormulateForm"
                      v-model="prePopulate"
                      name="medtwo"
                      :schema="medtwo"
                      :keep-model-data="true"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="this.selectedMedication[2]">
                <v-expansion-panel-header>
                  <span
                    class="text-base"
                  >Medication 3 Information: {{ this.selectedMedication[2].BrandName }} {{ this.selectedMedication[2].RxStrength }} </span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                    <FormulateForm
                      :key="refreshFormulateForm"
                      v-model="prePopulate"
                      name="medthree"
                      :schema="medthree"
                      :keep-model-data="true"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Signature
                  <v-icon
                    v-if="!patientProfile.PharmacistSignature"
                    color="warning"
                    medium
                  >
                    {{ icons.mdiAlert }}
                  </v-icon></span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <pg-sig-input
                  :key="signatureCompKey"
                  @updatePharmacist="updatePharmacist"
                  @save="saveSignature"
                >
                </pg-sig-input>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ patientProfile['rphfirstname'] }} </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                    >
                      <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                        <FormulateForm
                          :key="refreshFormulateForm"
                          v-model="prePopulate"
                          name="pharmacistinfo"
                          :schema="pharmacistinfo"
                          :keep-model-data="true"
                        />
                      </div>
                    </v-col>
                    <!-- <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
              <v-expansion-panel v-if="this.selectedMedication[0]">
                <v-expansion-panel-header>
                  <span
                    class="text-base"
                  >Doctor Information: Dr. {{ this.selectedMedication[0].FirstName }} {{ this.selectedMedication[0].LastName }}</span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                    <FormulateForm
                      :key="refreshFormulateForm"
                      v-model="prePopulate"
                      name="doctorinfo"
                      :schema="doctorinfo"
                      :keep-model-data="true"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>
      <div

        class="d-flex justify-center align-center"
      >
        <v-card-actions>
          <pg-form-buttons
            :step="activeStep"
            :final-step="3"
            @next="next"
            @previous="previous"
          ></pg-form-buttons>
          <!-- <v-col>
            <v-btn
              v-show="activeStep === 1"
              color="success"
              large
              @click="nextstepone"
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="success"
              large
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col> -->
          <v-col>
            <pg-submit
              v-show="activeStep === 3"
              id="v-step-7"
              form-type="verbalRx"
              base-filename="verbalRx"
              type-of-medscheck="typeOfMedscheck"

              :patient-data="prePopulate"
              @saveInfo="savePdfInfo($event)"
            >
              Submit
            </pg-submit>
            <save-patient-state
              :patient-profile="prePopulate"
              :label="saveState"
              @onload="OnLoad($event)"
              @click="statekeyRender"
            ></save-patient-state>
          </v-col>
        </v-card-actions>
      </div>
    </v-stepper>
    <v-dialog
      v-model="medSelectionDialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title class="tw-my-2 tw-justify-center">
          This form only allows 3 drug to be selected, select from your selection below:
        </v-card-title>
        <v-card-text>
          <v-list shaped>
            <v-list-item-group
              v-model="medSelectionList"
              max="3"
              multiple
            >
              <template v-for="(drug, i) in this.$store.state.Patient.selectedMedications">
                <v-list-item
                  :key="`${drug.DIN}`"
                  :value="drug"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="`${drug.GenericName} - ${drug.DIN}`"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        :disabled="medSelectionList.length > 2"
                        :input-value="active"
                        color="deep-purple accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="medSelectionDialog= false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="selectFromList"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingPatient"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiAlert, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import SingleMedicationsSelection from '@/components/phoxTables/drug/multiDrugMax3.vue'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'

// import SingleMedicationsSelection from '@/views/pages/rxSearch/SingleMedicationsSelectionNoRepeat.vue'
import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import SavePatientState from '@/views/pages/tools/SaveDocumentState.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormDataMedicationSelection'
import { stateService } from '@/render/api/zarya'
import {
  patientInfo,
  callerInfo,
  medone,
  medtwo,
  medthree,
  doctorinfo,
  pharmacistinfo,
} from '@/assets/formSchema/verbalRxSchema'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'
import pgFormButtons from '@/views/patient/components/pg-form-buttons.vue'

export default {
  name: 'VerbalRx',
  components: {
    'single-medication-selection': SingleMedicationsSelection,
    'pg-submit': submitPdf,
    'save-patient-state': SavePatientState,
    'pharmacist-search': PharmacistSearch,
    'pg-sig-input': pgSigInput,
    'pg-form-buttons': pgFormButtons,
  },

  data() {
    this.$store.dispatch('setFormClass', 'verbalRx')

    return {
      medSelectionList: [],
      medSelectionDialog: false,
      signatureCompKey: 0,
      patientInfo,
      callerInfo,
      medone,
      medtwo,
      medthree,
      doctorinfo,
      pharmacistinfo,
      saveState: 'Save Progress',
      dateOfTransaction: '',
      dateTimeofTransaction: '',
      rxDetails: '',
      finalpanel: 0,
      dataPanel: '',
      refreshFormulateForm: 0,
      loadingPatient: false,
      componentKeyMedList: 0,
      singleSelect: true,
      componentTopCardKey: 0,
      selectedDoctor: [],
      prePopulate: {},
      search: '',
      doctorList: [],
      selectedMedication: [],
      icons: { mdiAlert, mdiCheckboxMarkedCircleOutline },
      activeStep: 1,
      initial: '',
      patientProfile: {},
      headers: [
        {
          text: 'Last Name',
          class: 'black--text font-weight-bold subtitle-1',
          align: 'left',
          value: 'DoctorLastName',
          sortable: false,
        },
        {
          text: 'First Name',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFirstName',
          sortable: false,
        },
        {
          text: 'CPSO',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'CPSO',
          sortable: false,
        },
        {
          text: 'Address',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorAddress',
          sortable: false,
        },
        {
          text: 'Phone',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorPhone',
          sortable: false,
        },
        {
          text: 'Fax',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFax',
          sortable: false,
        },
        {
          text: 'Prescriptions in Last Year',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'RXperProvider',
          sortable: false,
        },
      ],
      headersCard: [
        {
          text: 'Generic Name',
          align: 'left',
          sortable: false,
          value: 'GenericName',
        },
        {
          text: 'Strength',
          align: 'left',
          sortable: false,
          value: 'RxStrength',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'FormClass',
      'PatientProfile', // get patient profile
      'PatientMedications', // get patient medication,
      'PharmacyProfile',
      'UserProfile',
    ]),
    ...mapState(['Patient']),
  },
  mounted() {
    this.populateWithPatientData()
    this.preloadForm()
    this.searchDoctor()
    this.refreshFormulateForm += 1
    this.saveDateSubmitted()
    if (this.$store.state.Patient.selectedMedications.length < 4) {
      this.selectedMedication = this.$store.state.Patient.selectedMedications
      this.componentKeyMedList++
    }
    if (this.$store.state.Patient.selectedMedications.length > 3) {
      this.medSelectionDialog = true
    }

    this.$root.$on('Refresh_Table', patientID => {
      this.preloadForm()
      this.populateWithPatientData()
      this.saveDateSubmitted()
      this.searchDoctor()
      this.selectedPatient()
      this.refreshFormulateForm += 1
      this.step = '1'
      this.finalpanel = 0

      // this.dataPanel = 0
    })
  },
  methods: {
    selectFromList() {
      // console.log(medication)
      this.selectedMedication = this.medSelectionList
      this.$store.dispatch('setSelectedPatientMedications', this.selectedMedication)
      this.componentKeyMedList++
      this.medSelectionDialog = false
    },
    previous() {
      this.activeStep--
      if (this.activeStep === 2) {
        this.patientProfile.rphfirstname = `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`
        this.patientProfile.ocp = `${this.$store.state.User.user.ocp}`
        this.signatureCompKey++
      }
    },
    next() {
      if (this.activeStep === 1) {
        this.populateWithPatientData()
        this.populateWithMedData()
      }
      this.activeStep++
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.prePopulate.rphfirstname = pharmacistName
      this.prePopulate.ocp = ocp
      this.prePopulate.rphName = pharmacistName
      this.prePopulate.rphfirstname = pharmacistName.split(' ')[0]
      this.prePopulate.rphlastname = pharmacistName.split(' ')[1]
      this.patientProfile.rphfirstname = pharmacistName
    },
    saveSignature({ pharmacistSig, signatureDate }) {
      this.prePopulate.PharmacistSignature = pharmacistSig
      this.prePopulate.rphsignature = pharmacistSig
      this.prePopulate.date = signatureDate
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.$store.dispatch('setSelectedPatientMedications', selectedMedication)
      this.selectedMedication = this.$store.state.Patient.selectedMedications
      this.patientProfile = {
        ...this.patientProfile,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,

        // originalRxnumber: this.selectedMedication[0].RxNumber,
      }
    },
    async searchDoctor() {
      this.doctorList =
        (await this.$root.$system.DBAdapterConn.getSearchByDoctor(
          this.PatientProfile.PatientID,
          this.drFirstName,
          this.drLastName,
          this.cpso,
        )) || []
    },
    async populateWithPatientData() {
      this.prePopulate = {
        ...this.patientProfile,
        patientlastname: this.PatientProfile['LAST NAME'],
        patientfirstname: this.PatientProfile['FIRST NAME'],
        patientdateofbirth: this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),
        'dob[year]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY'),
        'dob[month]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('MM'),
        'dob[day]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('DD'),
        rphName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
        dateSigned: this.$moment(this.patientProfile.dateSubmitted).format('YYYY-MM-DD'),
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        patientID: this.$store.getters.PatientProfile.PatientID,
        rphfirstname: this.UserProfile.firstName,
        rphlastname: this.UserProfile.lastName,
        ocp: this.UserProfile.ocp,
        PharmacistSignature: this.prePopulate.PharmacistSignature,
      }
      this.loadingPatient = false
    },
    async populateWithMedData() {
      if (this.selectedMedication.length === 1) {
        this.prePopulate = {
          ...this.prePopulate,
          date: this.$moment().local().format('YYYY-MM-DD'),
          time: this.$moment().local().format('HH:mm'),
          din1: this.selectedMedication[0].DIN,

          drfirstname: this.selectedMedication[0].FirstName,
          drlastname: this.selectedMedication[0].LastName,
          doctorCpso: this.selectedMedication[0].CPSO,
          doctorTelephoneNumber: this.selectedMedication[0].DoctorPhone,
          medicationName1: `${this.selectedMedication[0].BrandName} ${this.selectedMedication[0].RxStrength}`,

          medicationInstructions1: this.selectedMedication[0].SIGFull,
          medicationQuantity1: this.selectedMedication[0].RxQtyDispense,

          rxNumber1: this.selectedMedication[0].RxNumber,
          medicationRepeats1: Math.floor(
            this.selectedMedication[0].QtyRemain / this.selectedMedication[0].RxQtyDispense,
          ),
          RxNumber: this.selectedMedication[0].RxNumber,
          LastName: this.selectedMedication[0].LastName,
          FirstName: this.selectedMedication[0].FirstName,
          CPSO: this.selectedMedication[0].CPSO,
          DoctorPhone: this.selectedMedication[0].DoctorPhone,
          DoctorFax: this.selectedMedication[0].DoctorFax,
          RxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
          DIN: this.selectedMedication[0].DIN,
          GenericName: this.selectedMedication[0].GenericName,
          RxStrength: this.selectedMedication[0].RxStrength,
          Form: this.selectedMedication[0].Form,
          Schedule: this.selectedMedication[0].Schedule,
          BrandName: this.selectedMedication[0].BrandName,
          RxQtyDispense: this.selectedMedication[0].RxQtyDispense,
          QtyRemain: this.selectedMedication[0].QtyRemain,
          SIGFull: this.selectedMedication[0].SIGFull,
          QtyDispense: this.selectedMedication[0].QtyDispense,
          Days: this.selectedMedication[0].Days,
          TotalAuthorized: this.selectedMedication[0].TotalAuthorized,
        }
      } else if (this.selectedMedication.length === 2) {
        this.prePopulate = {
          ...this.prePopulate,
          date: this.$moment().local().format('YYYY-MM-DD'),
          time: this.$moment().local().format('HH:mm'),
          drfirstname: this.selectedMedication[0].FirstName,
          drlastname: this.selectedMedication[0].LastName,
          doctorCpso: this.selectedMedication[0].CPSO,
          doctorTelephoneNumber: this.selectedMedication[0].DoctorPhone,
          din2: this.selectedMedication[1].DIN,
          medicationName2: `${this.selectedMedication[1].BrandName} ${this.selectedMedication[1].RxStrength}`,
          medicationInstructions2: this.selectedMedication[1].SIGFull,
          medicationQuantity2: this.selectedMedication[1].RxQtyDispense,
          rxNumber2: this.selectedMedication[1].RxNumber,
          medicationRepeats2: Math.floor(
            this.selectedMedication[1].QtyRemain / this.selectedMedication[1].RxQtyDispense,
          ),
          din1: this.selectedMedication[0].DIN,
          medicationName1: `${this.selectedMedication[0].BrandName} ${this.selectedMedication[0].RxStrength}`,
          medicationInstructions1: this.selectedMedication[0].SIGFull,
          medicationQuantity1: this.selectedMedication[0].RxQtyDispense,
          rxNumber1: this.selectedMedication[0].RxNumber,
          medicationRepeats1: Math.floor(
            this.selectedMedication[0].QtyRemain / this.selectedMedication[0].RxQtyDispense,
          ),
          RxNumber: this.selectedMedication[0].RxNumber,
          LastName: this.selectedMedication[0].LastName,
          FirstName: this.selectedMedication[0].FirstName,
          CPSO: this.selectedMedication[0].CPSO,
          DoctorPhone: this.selectedMedication[0].DoctorPhone,
          DoctorFax: this.selectedMedication[0].DoctorFax,
          RxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
          DIN: this.selectedMedication[0].DIN,
          GenericName: this.selectedMedication[0].GenericName,
          RxStrength: this.selectedMedication[0].RxStrength,
          Form: this.selectedMedication[0].Form,
          Schedule: this.selectedMedication[0].Schedule,
          BrandName: this.selectedMedication[0].BrandName,
          RxQtyDispense: this.selectedMedication[0].RxQtyDispense,
          QtyRemain: this.selectedMedication[0].QtyRemain,
          SIGFull: this.selectedMedication[0].SIGFull,
          QtyDispense: this.selectedMedication[0].QtyDispense,
          Days: this.selectedMedication[0].Days,
          TotalAuthorized: this.selectedMedication[0].TotalAuthorized,
          RxNumber1: this.selectedMedication[1].RxNumber,
          DIN1: this.selectedMedication[1].DIN,
          GenericName1: this.selectedMedication[1].GenericName,
          RxStrength1: this.selectedMedication[1].RxStrength,
          Form1: this.selectedMedication[1].Form,
          BrandName1: this.selectedMedication[1].BrandName,
          RxQtyDispense1: this.selectedMedication[1].RxQtyDispense,
          QtyRemain1: this.selectedMedication[1].QtyRemain,
          SIGFull1: this.selectedMedication[1].SIGFull,
          QtyDispense1: this.selectedMedication[1].QtyDispense,
          Days1: this.selectedMedication[1].Days,
          TotalAuthorized1: this.selectedMedication[1].TotalAuthorized,
        }
      } else if (this.selectedMedication.length === 3) {
        this.prePopulate = {
          ...this.prePopulate,
          date: this.$moment().local().format('YYYY-MM-DD'),
          time: this.$moment().local().format('HH:mm'),
          din: this.selectedMedication[0].DIN,
          drfirstname: this.selectedMedication[0].FirstName,
          drlastname: this.selectedMedication[0].LastName,
          doctorCpso: this.selectedMedication[0].CPSO,
          doctorTelephoneNumber: this.selectedMedication[0].DoctorPhone,
          din2: this.selectedMedication[1].DIN,
          medicationName2: `${this.selectedMedication[1].BrandName} ${this.selectedMedication[1].RxStrength}`,
          medicationInstructions2: this.selectedMedication[1].SIGFull,
          medicationQuantity2: this.selectedMedication[1].RxQtyDispense,
          rxNumber2: this.selectedMedication[1].RxNumber,
          medicationRepeats2: Math.floor(
            this.selectedMedication[1].QtyRemain / this.selectedMedication[1].RxQtyDispense,
          ),
          din1: this.selectedMedication[0].DIN,
          medicationName1: `${this.selectedMedication[0].BrandName} ${this.selectedMedication[0].RxStrength}`,
          medicationInstructions1: this.selectedMedication[0].SIGFull,
          medicationQuantity1: this.selectedMedication[0].RxQtyDispense,
          rxNumber1: this.selectedMedication[0].RxNumber,
          medicationRepeats1: Math.floor(
            this.selectedMedication[0].QtyRemain / this.selectedMedication[0].RxQtyDispense,
          ),
          din3: this.selectedMedication[2].DIN,
          medicationName3: `${this.selectedMedication[2].BrandName} ${this.selectedMedication[2].RxStrength}`,
          medicationInstructions3: this.selectedMedication[2].SIGFull,
          medicationQuantity3: this.selectedMedication[2].RxQtyDispense,
          rxNumber3: this.selectedMedication[2].RxNumber,
          medicationRepeats3: Math.floor(
            this.selectedMedication[2].QtyRemain / this.selectedMedication[2].RxQtyDispense,
          ),
          RxNumber: this.selectedMedication[0].RxNumber,
          LastName: this.selectedMedication[0].LastName,
          FirstName: this.selectedMedication[0].FirstName,
          CPSO: this.selectedMedication[0].CPSO,
          DoctorPhone: this.selectedMedication[0].DoctorPhone,
          DoctorFax: this.selectedMedication[0].DoctorFax,
          RxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
          DIN: this.selectedMedication[0].DIN,
          GenericName: this.selectedMedication[0].GenericName,
          RxStrength: this.selectedMedication[0].RxStrength,
          Form: this.selectedMedication[0].Form,
          Schedule: this.selectedMedication[0].Schedule,
          BrandName: this.selectedMedication[0].BrandName,
          RxQtyDispense: this.selectedMedication[0].RxQtyDispense,
          QtyRemain: this.selectedMedication[0].QtyRemain,
          SIGFull: this.selectedMedication[0].SIGFull,
          QtyDispense: this.selectedMedication[0].QtyDispense,
          Days: this.selectedMedication[0].Days,
          TotalAuthorized: this.selectedMedication[0].TotalAuthorized,
          RxNumber1: this.selectedMedication[1].RxNumber,
          DIN1: this.selectedMedication[1].DIN,
          GenericName1: this.selectedMedication[1].GenericName,
          RxStrength1: this.selectedMedication[1].RxStrength,
          Form1: this.selectedMedication[1].Form,
          BrandName1: this.selectedMedication[1].BrandName,
          RxQtyDispense1: this.selectedMedication[1].RxQtyDispense,
          QtyRemain1: this.selectedMedication[1].QtyRemain,
          SIGFull1: this.selectedMedication[1].SIGFull,
          QtyDispense1: this.selectedMedication[1].QtyDispense,
          Days1: this.selectedMedication[1].Days,
          TotalAuthorized1: this.selectedMedication[1].TotalAuthorized,
          RxNumber2: this.selectedMedication[2].RxNumber,
          DIN2: this.selectedMedication[2].DIN,
          GenericName2: this.selectedMedication[2].GenericName,
          RxStrength2: this.selectedMedication[2].RxStrength,
          Form2: this.selectedMedication[2].Form,
          Schedule2: this.selectedMedication[2].Schedule,
          BrandName2: this.selectedMedication[2].BrandName,
          RxQtyDispense2: this.selectedMedication[2].RxQtyDispense,
          QtyRemain2: this.selectedMedication[2].QtyRemain,
          SIGFull2: this.selectedMedication[2].SIGFull,
          QtyDispense2: this.selectedMedication[2].QtyDispense,
          Days2: this.selectedMedication[2].Days,
          TotalAuthorized2: this.selectedMedication[2].TotalAuthorized,
        }
      }
      this.loadingPatient = false
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedPatient() {
      this.selectedMedication = []
      this.prePopulate = {}
      this.activeStep = 1

      this.componentTopCardKey += 1

      // this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
      this.populateWithPatientData()
    },
    savePdfInfo(data) {
      this.prePopulate.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    selectedDoctorClick() {
      this.prePopulate = {
        ...this.prePopulate,
        drName: `${this.selectedDoctor[0].DoctorFirstName} ${this.selectedDoctor[0].DoctorLastName}`,
        drFax: this.selectedDoctor[0].DoctorFax,
        cpso: this.selectedDoctor[0].CPSO,
        drTel: this.selectedDoctor[0].DoctorPhone,
      }
    },
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.prePopulate = Object.assign(this.prePopulate, {
          rphfirstname: UserName.split(' ', 1),
          rphlastname: UserName.split(' ')[UserName.split(' ').length - 1],
          ocp: PharmID,
        })
        this.patientProfile.rphfirstname = `${this.prePopulate.rphfirstname}  ${this.prePopulate.rphlastname}`
      })
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
      this.dateOfTransaction = date
      this.dateTimeofTransaction = dateTime
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.$store.state.Patient.FORM_CLASS,
        state: this.prePopulate,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }

      // this.$router.push('/patient/patientdashboard')
      this.$router.push({
        name: 'dashboard-document',
        query: {
          tab: 5,
        },
      })
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }

        // eslint-disable-next-line no-undef
        res = await stateService.updateState(token, this.patientProfile.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }

      // update it - once medcheck is complete and it has state id, change to complete -- works
    },
    nextstepone() {
      this.populateWithPatientData()
      this.populateWithMedData()

      // this.medicationsForPDF(this.patientData)

      this.activeStep = 2

      // this.refreshSignatureStatus += 1
    },
    nextstep() {
      this.activeStep = 3
      this.refreshSignatureStatus += 1
    },
    ProcessMeds(patientData) {
      return serverFormProcess(this.selectedMedication, this.medicationsForPDF(patientData))
    },
    medicationsForPDF(patientData) {
      this.selectedMedication.forEach((med, idx) => {
        patientData = {
          ...patientData,
          [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${med['GENERIC NAME'] || med.GenericName} - ${
            med.STRENGTH || med.RxStrength
          } - Qty: ${med.RxQtyDispense} - Qty Auth:${med.TotalAuthorized}`,
        }
      })
      this.rxDetails = patientData

      return patientData
    },
    statekeyRender() {
      this.componentKeyinteractions += 1
    },
  },
}
</script>

<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}

#headersTable {
  .theme--dark.v-data-table {
    background-color: #95a6df80 !important;
    color: #ffffff !important;
  }
  .theme--dark.v-data-table td {
    color: rgb(255 255 255) !important;
  }
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
#buttons {
  margin: 1%;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.centerButtons button {
  margin: 0 2%;
}
iframe {
  width: 1px;
  min-width: 100%;
}
</style>
